.main{
    /* background-color: #ffffff; */
}

.report-form{
    width: 100%;
    font-size: 0.9rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.field-validation-required{
    background: rgb(255, 238, 238);
}

.validation-failed-error, .validation-failed-field-error {
    background: rgb(255, 238, 238);
    padding: 10px;
    margin: 5px;
    color: red;
    border-radius: 5px;
    border: 1px solid rgb(251, 193, 193);
}